/// <reference types="grecaptcha" />

export default function useRecaptchaV3() {
	const v3Key = useRuntimeConfig().public.recaptchaSiteKeyV3;

	async function executeV3recaptcha(action = 'submit'): Promise<string> {
		return new Promise((resolve, reject) => {
			if (typeof grecaptcha === 'undefined') {
				reject(new Error('grecaptcha is not defined'));
				return;
			}

			grecaptcha.ready(() => {
				(grecaptcha.execute(v3Key, { action }) as Promise<string>)
					.then((token) => {
						resolve(token);
					})
					.catch((error) => {
						reject(error);
					});
			});
		});
	}

	return { executeV3recaptcha };
}
